/* Container */
.navbar-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    background: transparent;
    transition: all 0.3s ease;
    font-family: var(--font-primary), sans-serif;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}

.navbar-container.scrolled {
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
    background: rgba(255, 255, 255, 0.2);
}

.navbar-container.scrolled.dark-mode {
    background: rgba(0, 0, 0, 0.2);
}

.navbar-container.scrolled.light-mode {
    background: rgba(255, 255, 255, 0.2);
}

.navbar-content {
    max-width: 82rem;
    width: 100%;
    height: 4rem;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    align-items: center;
    padding: 0 1rem;
}

/* Logo Section */
.navbar-logo-container {
    cursor: pointer;
}

.navbar-logo {
    height: 0.875rem;
    width: auto;
}

.navbar-logo path {
    fill: white;
}

/* Navigation Menu */
.navbar-links {
    display: flex;
    gap: 0.5rem;
    justify-content: center;
    grid-column: 2;
    align-items: center;
    height: 100%;
}

.nav-dropdown-container {
    height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
}

.nav-dropdown-container:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.navbar-link {
    cursor: pointer;
    color: white;
    font-size: 1rem;
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    gap: 6px;
    opacity: 0.9;
}

.navbar-link::after {
    content: none;
}

.caret {
    color: white;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: translateY(1px);
    transition: transform 0.3s ease;
}

.nav-dropdown-container:hover .caret {
    transform: translateY(1px) rotate(180deg);
}

.nav-dropdown-panel {
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    padding: 12px;
    min-width: 280px;
    opacity: 0;
    transform: translateY(10px);
    pointer-events: none;
    visibility: hidden;
    transition: all 0.8s ease;
}

.nav-dropdown-item {
    padding: 12px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.2s ease;
}

.nav-dropdown-item:hover {
    background: rgba(0, 0, 0, 0.03);
}

.nav-dropdown-item h3 {
    color: #333;
    font-size: 0.9rem;
    margin-bottom: 4px;
}

.nav-dropdown-item p {
    color: #666;
    font-size: 0.8rem;
    margin: 0;
}

/* Right Container */
.navbar-auth {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: flex-end;
}

.navbar-auth-buttons {
    display: flex;
    gap: 1rem;
    align-items: center;
}

/* Login Button */
.navbar-login {
    cursor: pointer;
    padding: 0.5rem 1rem;
    color: var(--text-color);
    font-family: var(--font-primary), sans-serif;
}

/* Sign Up Button */
.navbar-signup {
    cursor: pointer;
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.1);
    color: white;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.navbar-signup:hover {
    background: rgba(255, 255, 255, 0.2);
}

.navbar-signup-circle {
    width: 14px;
    height: 14px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    position: relative;
}

.navbar-signup-circle::before {
    content: "";
    position: absolute;
    width: 6px;
    height: 6px;
    background: white;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* User Info */
.navbar-user-info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.navbar-user-name {
    color: var(--text-color);
    font-family: var(--font-primary), sans-serif;
}

.navbar-logout {
    padding: 0.5rem 1rem;
    background: var(--button-bg);
    color: var(--text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: var(--font-primary), sans-serif;
}

.navbar-logout:hover {
    background: var(--button-hover);
}

/* Fixed Navbar */
.fixed-navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
}

/* Add these to navbar.css */
@media (max-width: 1024px) {
    .navbar-container {
        padding: 0 1rem;
    }
    
    .navbar-content {
        padding: 0 0.5rem;
    }
}

@media (max-width: 768px) {
    .navbar-container {
        padding: 0 24px;
    }
    
    .navbar-content {
        grid-template-columns: auto auto;
    }
    
    .navbar-links {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        background: white;
        flex-direction: column;
        padding: 1rem;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    }
    
    .navbar-links.active {
        display: flex;
    }
    
    .navbar-link {
        width: 100%;
        padding: 0.75rem;
    }
}

/* Center the submenu */
.navbar {
    position: relative;
}

.submenu {
    display: flex;
    justify-content: center; /* Center the submenu */
    position: absolute;
    top: 100%; /* Position below the navbar */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    width: 80%; /* Adjust width as needed */
    background-color: white; /* Background color */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow */
    padding: 10px;
    box-sizing: border-box;
}

/* Make submenu items 4 wide */
.submenu-item {
    flex: 1 1 25%; /* Four items wide */
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
}

/* Login/Signup button styles */
.navbar-auth-buttons .navbar-signup-text {
    color: white;
}

.navbar-auth-buttons .navbar-signup-circle {
    border: 1px solid white;
    background: transparent;
}
