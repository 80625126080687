.indiv-dashboard-container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
}

.indiv-dashboard-sidebar {
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  width: auto;
  flex-shrink: 0;
  background-color: #17332D;
  background-image: url('../assets/images/boreal-grain.png');
  background-blend-mode: normal;
  background-size: contain;
  background-repeat: repeat;
}

.indiv-dashboard-logo-section {
  align-self: stretch;
  padding: 16px 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.indiv-dashboard-logo-wrapper {
  height: 32px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.indiv-dashboard-logo-inner {
  flex: 1 1 0;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.indiv-dashboard-logo-icon {
  width: 16px;
  height: 12px;
  background: transparent;
  color: #F8F8F5;
}

.indiv-dashboard-nav-menu {
  width: 48px;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.indiv-dashboard-nav-item {
  padding: 10px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.indiv-dashboard-nav-icon {
  width: 16px;
  height: 16px;
  position: relative;
}

.indiv-dashboard-nav-icon-circle {
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 9999px;
  border: 1px white solid;
}

.indiv-dashboard-nav-icon-bar {
  width: 4px;
  height: 8.5px;
  position: absolute;
  background: white;
  left: 6px;
  top: 4px;
}

.indiv-dashboard-content {
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  background: white;
  display: flex;
  flex-direction: column;
}

.indiv-dashboard-header-new {
  padding: 80px 48px 20px 48px;
  background: #EEEEEE;
}

.indiv-dashboard-welcome-text-new {
  color: black;
  font-size: var(--text-3xl);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: var(--text-offset);
}

.indiv-dashboard-content-area-new {
  flex: 1;
  padding: 3rem;
  background: #E9E7E6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.indiv-dashboard-card-new {
  width: 100%;
  min-height: 300px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.70);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  backdrop-filter: blur(30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.indiv-dashboard-card-title-new {
  color: black;
  font-size: var(--text-2xl);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: var(--text-offset);
}

.indiv-dashboard-balance-section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.indiv-dashboard-balance-header {
  width: 100%;
  color: black;
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.indiv-dashboard-balance-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0;
  margin: 0;
  position: relative;
  overflow: hidden;
}

.balance-graph-background {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  z-index: 0;
}

.indiv-dashboard-balance-amount-container,
.indiv-dashboard-profit {
  position: relative;
  z-index: 1;
}

.indiv-dashboard-balance-amount-container {
  display: flex;
  align-items: center;
}

.indiv-dashboard-balance-number {
  color: black;
  font-size: 36px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium-weight);
}

.indiv-dashboard-eye-icon {
  width: 32px;
  height: 32px;
  background: #D8D8D8;
  border-radius: 9999px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indiv-dashboard-profit {
  color: #34A853;
  font-size: var(--text-xs);
  font-family: var(--font-medium);
  font-weight: var(--font-medium-weight);
}

.indiv-dashboard-grid {
  width: 100%;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.indiv-dashboard-grid-row {
  align-self: stretch;
  flex: 1 1 0;
  display: flex;
  gap: 8px;
  width: 100%;
}

.indiv-dashboard-grid-item {
  flex: 1 1 0;
  padding: 12px;
  background: white;
  border-radius: 10px;
  border: 1px solid #656565;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  justify-content: space-between;
}

.indiv-dashboard-grid-icon {
  width: 32px;
  height: 32px;
  padding: 10px;
  background: #656565;
  border-radius: 5px;
}

.indiv-dashboard-grid-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.indiv-dashboard-grid-text {
  display: flex;
  flex-direction: column;
  gap: 0px;
}

.indiv-dashboard-grid-title {
  color: #656565;
  font-size: var(--text-sm);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.indiv-dashboard-grid-subtitle {
  color: #656565;
  font-size: var(--text-xs);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.indiv-dashboard-contributions {
  height: 106px;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.indiv-dashboard-contributions-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.indiv-dashboard-progress-bar {
  width: 100%;
  height: 4rem;
  display: flex;
}

.indiv-dashboard-progress-fill {
  height: 4rem;
  background: #17332D;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 1rem;
  padding-left: 1rem;
  width: 100%;
}

.indiv-dashboard-progress-fill span {
  color: white;
  font-size: var(--text-xl);
  font-family: var(--font-medium);
  font-weight: var(--font-medium-weight);
}

.indiv-dashboard-progress-remainder {
  width: 44px;
  height: 4rem;
  background: #FFB700;
}

.indiv-dashboard-notifications {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.indiv-dashboard-notification-item {
  width: 100%;
  padding: 12px;
  background: white;
  border-radius: 10px;
  border: 1px solid #656565;
  display: flex;
  align-items: center;
  gap: 16px;
}

.indiv-dashboard-arrow {
  width: 10px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indiv-dashboard-arrow img {
  width: 100%;
  height: 100%;
}

.indiv-dashboard-footer {
  align-self: stretch;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.indiv-dashboard-footer-text {
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: translateY(3px);
}

.contribution-input {
  position: relative;
}

.dropdown-trigger {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: white;
  border: 1px solid #656565;
  border-radius: 5px;
  z-index: 10;
}

.link-account-button {
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none;
  background: none;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: var(--text-base);
}

.contribution-amount {
  display: flex;
  align-items: center;
  gap: 8px;
}

.contribution-next-button {
  padding: 12px 24px;
  background: #17332D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: var(--text-base);
}

@media (max-width: 1024px) {
  .indiv-dashboard-content-area-new {
    grid-template-columns: 1fr;
  }
}

.indiv-dashboard-card-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

/* For arrows in grid items */
.indiv-dashboard-grid-arrow {
  width: 10px;
  height: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indiv-dashboard-grid-arrow img {
  width: 100%;
  height: 100%;
}

/* For arrows in card footers/headers */
.indiv-dashboard-card-arrow {
  width: 0.9rem;
  height: 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indiv-dashboard-card-arrow img {
  width: 100%;
  height: 100%;
}

.indiv-dashboard-notifications-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

/* Settings Form Styles */
.settings-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.settings-form-row {
  display: flex;
  gap: 24px;
}

.settings-form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 8px;
}

.settings-form-group label {
  font-size: var(--text-sm);
  font-family: var(--font-primary);
  color: #656565;
}

.settings-form-group input {
  padding: 12px;
  border: 1px solid #656565;
  border-radius: 5px;
  font-size: var(--text-base);
  font-family: var(--font-primary);
}

.settings-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 16px;
}

.settings-save-button {
  padding: 12px 24px;
  background: #17332D;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: var(--text-base);
  transition: background-color 0.2s ease;
}

.settings-save-button:hover {
  background: #1f4940;
}

.settings-save-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.settings-cancel-button {
  padding: 12px 24px;
  background: transparent;
  color: #656565;
  border: 1px solid #656565;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: var(--text-base);
  transition: all 0.2s ease;
}

.settings-cancel-button:hover {
  background: #f5f5f5;
}

.change-password-button {
  padding: 12px 24px;
  background: transparent;
  color: #17332D;
  border: 1px solid #17332D;
  border-radius: 5px;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: var(--text-base);
  transition: all 0.2s ease;
}

.change-password-button:hover {
  background: #f5f5f5;
}

.message {
  padding: 16px;
  border-radius: 5px;
  margin-bottom: 16px;
  font-family: var(--font-primary);
}

.message.success {
  background: #e6f4ea;
  color: #1e4620;
}

.message.error {
  background: #fce8e6;
  color: #c5221f;
}

@media (max-width: 768px) {
  .settings-form-row {
    flex-direction: column;
    gap: 16px;
  }
}


/* Settings Sections */
.settings-section {
  background: white;
  border-radius: 8px;
  padding: 2rem;
  width: 100%;
  margin-bottom: 2rem;
}

.settings-section:last-child {
  margin-bottom: 0;
}

.settings-section-title {
  font-size: 1.25rem;
  font-weight: 500;
  color: #111827;
  margin-bottom: 1.5rem;
}

/* Form Fields */
.settings-form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.settings-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  position: relative;
}

.settings-label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.settings-required {
  color: #DC2626;
  margin-left: 0.25rem;
}

.settings-input {
  padding: 0.75rem 1rem;
  border: 1px solid #D1D5DB;
  border-radius: 6px;
  font-size: 0.875rem;
  width: 100%;
  transition: border-color 0.2s;
}

.settings-input:focus {
  outline: none;
  border-color: #2563EB;
  box-shadow: 0 0 0 3px rgba(37, 99, 235, 0.1);
}

.settings-input:disabled {
  background-color: #F3F4F6;
  cursor: not-allowed;
}

/* Password Input */
.settings-password-input {
  position: relative;
  display: flex;
  align-items: center;
}

.settings-password-toggle {
  position: absolute;
  right: 1rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #6B7280;
}

.settings-password-eye {
  font-size: 1.25rem;
}

/* Verification Badge */
.settings-verified {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #059669;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.settings-verified::before {
  content: "✓";
  font-weight: bold;
}

/* Action Buttons */
.settings-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 1px solid #E5E7EB;
}

.settings-save-button {
  background-color: #2563EB;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 0.875rem;
  font-weight: 500;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
}

.settings-save-button:hover {
  background-color: #1D4ED8;
}

.settings-save-button:disabled {
  background-color: #93C5FD;
  cursor: not-allowed;
}

/* Message Styles */
.settings-message {
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 6px;
  font-size: 0.875rem;
}

.settings-message.success {
  background-color: #ECFDF5;
  color: #059669;
  border: 1px solid #A7F3D0;
}

.settings-message.error {
  background-color: #FEF2F2;
  color: #DC2626;
  border: 1px solid #FECACA;
}

.indiv-dashboard-content-area-nogrid {
  background: #e9e7e6;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  gap: 2rem;
  padding: 3rem;
}

.indiv-signup-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

.indiv-signup-input-field {
  width: 100%; /* Default full width */
}

.indiv-signup-input-field.half-width {
  width: 50%;
}

.indiv-signup-input-field.third-width {
  width: calc((100% - 32px) / 3); /* Accounts for the gaps between fields */
}

.indiv-signup-form-group {
  width: 100%;
  margin-bottom: 32px;
}

.indiv-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
}

.indiv-signup-input-row:last-child,
.indiv-signup-input-field:last-child {
  margin-bottom: 0;
}

/* Update form group spacing */
.indiv-signup-form-group {
  width: 100%;
  margin-bottom: 32px;
}

.indiv-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
}

/* Add spacing between ALL input fields */
.indiv-signup-input-field {
  margin-bottom: 16px; /* Space between input fields */
  width: 100%;
}

/* Add spacing between input rows */
.indiv-signup-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

/* Remove margin from last elements to prevent extra spacing */
.indiv-signup-form-group:last-child {
  margin-bottom: 0;
}

.indiv-signup-input-row:last-child {
  margin-bottom: 0;
}

/* Only remove bottom margin from input fields that are inside input rows */
.indiv-signup-input-row .indiv-signup-input-field {
  margin-bottom: 0;
}

/* Email input with badge */
.settings-input-with-badge {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}

.settings-input-with-badge .settings-input {
  width: 100%;
  padding-right: 80px; /* Make room for the badge */
}

.settings-verified {
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #059669;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background: #F3F4F6;
  padding: 2px 8px;
  border-radius: 4px;
}

.settings-verified::before {
  content: "✓";
  font-weight: bold;
}

/* Style the select dropdown */
select.settings-input {
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1em;
  padding-right: 2.5rem;
}

select.settings-input::-ms-expand {
  display: none;
}

select.settings-input option {
  color: #374151;
  background-color: white;
}