/* General styles */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  width: 100vw;
}

* {
  box-sizing: border-box;
}


/* App styles */
.App {
  width: 100vw;
}

/* Navbar styles */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  width: 100vw;
}

.logo {
  font-size: 1.5em;
  font-weight: var(--font-medium);
}

.nav-buttons {
  display: flex;
  gap: 10px;
}

.nav-buttons button {
  background-color: white;
  color: #007bff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}

.nav-buttons button:hover {
  background-color: #e0e0e0;
}

.signin-button button {
  background-color: white;
  color: #007bff;
  border: none;
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

.signin-button button:hover {
  background-color: #e0e0e0;
}

/* Landing page styles */
.landing-page {
  background-color: #f0f8ff;
  color: #333;
  padding: 50px 20px;
  min-height: calc(100vh - 60px);
  /* Adjust for navbar height */
}

.landing-page h1 {
  font-size: 3em;
  margin-bottom: 20px;
}

.landing-page p {
  font-size: 1.2em;
}

