.maintenance-container {
    width: 100%;
    height: 100vh;
    padding: 10px 96px;
    background: #17332D;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
  }
  
  .maintenance-content {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 64px;
  }
  
  .maintenance-text-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 24px;
  }
  
  .maintenance-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 30px;
  }
  
  .maintenance-title {
    width: 351px;
    color: white;
    font-size: 48px;
    font-family: var(--font-primary);
    font-weight: var(--font-normal);
    line-height: 48px;
    word-wrap: break-word;
  }
  
  .maintenance-description {
    width: 335.38px;
    opacity: 0.8;
    color: white;
    font-size: 14px;
    font-family: var(--font-primary);
    font-weight: var(--font-normal);
    line-height: 22.4px;
    word-wrap: break-word;
  }
  
  .button-container {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
  
  .maintenance-button {
    padding: 12px 24px;
    background: white;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
  }
  
  .button-text {
    color: black;
    font-size: 14px;
    font-family: 'Monument Grotesk';
    font-weight: var(--font-normal);
    word-wrap: break-word;
  }

  .maintenance-button {
    /* existing styles */
    text-decoration: none;  /* removes underline from link */
    cursor: pointer;
  }
  
  .maintenance-button:hover {
    opacity: 0.9;
  }