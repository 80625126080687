.login-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #17332D;
  padding: 20px;
}

.login-box {
  background: white;
  padding: 40px;
  border-radius: 8px;
  width: 100%;
  max-width: 400px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.login-box h2 {
  color: #17332D;
  margin-bottom: 24px;
  font-family: var(--font-primary), sans-serif;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #17332D;
  font-family: var(--font-primary), sans-serif;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
  font-family: var(--font-primary), sans-serif;
}

.login-button, .signup-button {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  background-color: #17332D;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  font-family: var(--font-primary), sans-serif;
}

.signup-button {
  background-color: transparent;
  border: 1px solid #17332D;
  color: #17332D;
}

.login-button:hover {
  background-color: #2A5950;
}

.signup-button:hover {
  background-color: rgba(23, 51, 45, 0.1);
}

.error-message {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 12px;
  border-radius: 4px;
  margin-bottom: 16px;
  font-family: var(--font-primary), sans-serif;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
} 