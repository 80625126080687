.nav-dropdown-container {
  position: relative;
}

.nav-dropdown-panel {
  position: fixed;
  top: 4rem;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
  padding: 24px;
  width: 800px;
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  transition: all 0.2s ease;
  z-index: 1000;
}

.nav-dropdown-panel.show {
  opacity: 1;
  pointer-events: auto;
  visibility: visible;
}

.dropdown-section {
  margin-bottom: 24px;
}

.dropdown-section-title {
  font-size: 15px;
  color: #111;
  margin-bottom: 16px;
  font-weight: 500;
}

.dropdown-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.dropdown-item {
  display: flex;
  align-items: flex-start;
  padding: 12px;
  border-radius: 8px;
  transition: background-color 0.2s ease;
}

.dropdown-item:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.dropdown-item-icon {
  width: 32px;
  height: 32px;
  background: #f5f5f5;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  flex-shrink: 0;
}

.dropdown-item-content {
  flex: 1;
}

.dropdown-item-content h3 {
  font-size: 14px;
  font-weight: 500;
  color: #111;
  margin: 0 0 4px 0;
}

.dropdown-item-content p {
  font-size: 13px;
  color: #666;
  margin: 0;
  line-height: 1.4;
}

.caret {
  color: white;
  transform: rotate(0deg);
  display: inline-block;
  margin-left: 4px;
  font-size: 0.8rem;
  transition: transform 0.3s ease;
}

.caret.rotated {
  transform: rotate(180deg);
}

.nav-dropdown-container:hover .caret {
  transform: rotate(180deg);
}

.nav-dropdown-section {
  padding: 24px;
}

.nav-dropdown-section:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 24px;
}

.nav-dropdown-section-content {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}

.nav-dropdown-section-title {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin-bottom: 16px;
}
