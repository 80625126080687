.employer-sidebar {
    position: relative;
    width: 50px;
    transition: width 0.3s ease;
    background-color: rgb(244, 243, 239);
    border-right: 1px solid rgb(219, 218, 201);
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.employer-sidebar.expanded {
    width: 200px;
}

.employer-logo-section {
    margin: 1rem 0.5rem 1rem 0.5rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 0rem 0.5rem 0rem 0.5rem;
}

.employer-logo-wrapper {
    align-self: center;
}

.employer-logo-inner {
    width: 16px;
    height: 24px;
    filter: none;
}

.employer-logo-icon {
    width: 100%;
    height: 100%;
    color: #2E2E27;
}

.employer-nav-menu {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    border-top: 1px solid rgba(46, 46, 39, 0.1);
    padding-top: 0.5rem;
    flex: 1;
}

.employer-primary-nav {
    display: flex;
    flex-direction: column;
}

.employer-secondary-nav {
    display: flex;
    flex-direction: column;
    margin-top: auto;
    border-top: 1px solid rgba(46, 46, 39, 0.1);
    padding-top: 0.5rem;
}

.employer-nav-item {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: #2E2E27;
    border-radius: 5px;
    margin: 0.25rem 0.5rem;
}

.nav-icon-container {
    position: relative;
    width: 34px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.employer-nav-label {
    position: absolute;
    left: 36px;
    white-space: nowrap;
    color: #2E2E27;
    font-size: 14px;
    opacity: 0;
    transition: opacity 0.3s ease;
    pointer-events: none;
    transform: var(--text-offset);
    font-family: 'TWK Lausanne 500';
}

.employer-sidebar.expanded .employer-nav-label {
    opacity: 1;
}

.employer-signout-section {
    margin-top: auto;
}

.employer-signout-wrapper {
    cursor: pointer;
    padding: 1rem 1rem 1rem 0.92rem;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.employer-signout-content {
    display: flex;
    align-items: center;
    color: #2E2E27;
}

.employer-signout-icon {
    width: 16px;
    height: 16px;
    min-width: 16px;
}

.employer-signout-text {
    margin-left: 12px;
    white-space: nowrap;
    color: #2E2E27;
    font-size: 14px;
    transform: translateY(4px);
}

.sidebar-toggle {
    position: relative;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #2E2E27;
}

.sidebar-toggle svg {
    width: 16px;
    height: 16px;
    fill: currentColor;
}

.employer-nav-item:hover,
.employer-signout-wrapper:hover {
    background-color: rgba(46, 46, 39, 0.1);
}

.sidebar-toggle img {
    width: 16px;
    height: 16px;
    transition: transform 0.2s ease;
    filter: none;
}

.sidebar-toggle:hover img {
    transform: scale(1.1);
}

.employer-nav-icon {
    width: 20px;
    height: 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.employer-nav-icon img {
    width: 20px;
    height: 20px;
    display: block;
    filter: none;
}

.employer-sidebar.expanded .employer-nav-item {
    justify-content: flex-start;
}

