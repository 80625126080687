.wallet-animate-path {
    transform-origin: 0px 50%;
    transform: scaleX(0.825) skewY(10deg) translateZ(0);
    transition: transform 0.3s ease-in-out;
  }
  
  @keyframes walletAnimation {
    0% {
      transform: scaleX(0.825) skewY(10deg) translateZ(0);
    }
    50% {
      transform: scaleX(1) skewY(0deg) translateZ(0);
    }
    100% {
      transform: scaleX(0.825) skewY(10deg) translateZ(0);
    }
  }
  
  .wallet-animate-path.animate {
    animation: walletAnimation 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .individual-sidebar.expanded .individual-nav-label {
      opacity: 1;
      pointer-events: auto;
  }
  
  .user-animate-path {
      transform: matrix(1, 0, 0, 1, 0, 0);  /* Center position */
      transition: transform 0.3s ease-in-out;
    }
    
    @keyframes userAnimation {
      0% {
        transform: matrix(1, 0, 0, 1, 0, 0);  /* Center */
      }
      25% {
        transform: matrix(1, 0, 0, 1, -2, 0);  /* Move left */
      }
      75% {
        transform: matrix(1, 0, 0, 1, 2, 0);  /* Move right */
      }
      100% {
        transform: matrix(1, 0, 0, 1, 0, 0);  /* Back to center */
      }
    }
    
    .user-animate-path.animate {
      animation: userAnimation 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    }
  
  /* Initial positions */
  .coin-1 {
    transform-origin: center;
    transform: translateY(0);
  }
  
  .coin-2 {
    transform-origin: center;
    transform: translateY(0);
  }
  
  .hand {
    transform-origin: bottom left;
    transform: translateY(0);
  }
  
  /* Animations */
  @keyframes coinToss1 {
    0% { transform: translateY(0px); }
    50% { transform: translateY(-1px); }
    75% { transform: translateY(9px) translateX(4px); }
    100% { transform: translateY(0px); }
  }
  
  @keyframes coinToss2 {
    0% { transform: translateY(0px); }
    25% { transform: translateY(-1px); }
    50% { transform: translateY(12px) translateX(5px); }
    100% { transform: translateY(0px); }
  }
  
  @keyframes handToss {
    0% { transform: rotate(0deg); }
    50% { transform: rotate(10deg); }
    100% { transform: rotate(0deg); }
  }
  
  /* Apply animations when active */
  .coin-1.animate {
    animation: coinToss1 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .coin-2.animate {
    animation: coinToss2 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .hand.animate {
    animation: handToss 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .help-icon {
    transform-origin: center;
    transform: scale(1) rotate(0deg);
  }
  
  @keyframes helpAnimation {
    0% { transform: scale(1) rotate(0deg); }
    50% { transform: scale(1.05) rotate(15deg); }
    100% { transform: scale(1) rotate(0deg); }
  }
  
  .help-icon.animate {
    animation: helpAnimation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .home-line-1 {
    transform-origin: center;
    transform: translateX(0);
  }
  
  .home-line-2 {
    transform-origin: center;
    transform: translateX(0);
  }
  
  @keyframes homeLine1Animation {
    0% { transform: translateX(0); }
    25% { transform: translateX(-1.5px); }
    75% { transform: translateX(1px); }
    100% { transform: translateX(0); }
  }
  
  @keyframes homeLine2Animation {
    0% { transform: translateX(0); }
    25% { transform: translateX(1.5px); }
    75% { transform: translateX(-1px); }
    100% { transform: translateX(0); }
  }
  
  .home-line-1.animate {
    animation: homeLine1Animation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .home-line-2.animate {
    animation: homeLine2Animation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  /* Transfer Icon Animation */
  .arrow-right {
    transform-origin: center;
    transform: translateX(0);
  }
  
  .arrow-left {
    transform-origin: center;
    transform: translateX(0);
  }
  
  @keyframes arrowRightAnimation {
    0% { transform: translateX(0); }
    25% { transform: translateX(2px); }
    75% { transform: translateX(-1px); }
    100% { transform: translateX(0); }
  }
  
  @keyframes arrowLeftAnimation {
    0% { transform: translateX(0); }
    25% { transform: translateX(-2px); }
    75% { transform: translateX(1px); }
    100% { transform: translateX(0); }
  }
  
  .arrow-right.animate {
    animation: arrowRightAnimation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .arrow-left.animate {
    animation: arrowLeftAnimation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  /* Entities Icon Animation */
  .long-end {
    transform-origin: bottom center;
    transform: scaleY(1);
  }
  
  .long-end-lines {
    transform-origin: center;
    transform: translateY(0);
  }
  
  .short-end {
    transform-origin: bottom center;
    transform: scaleY(1);
  }
  
  @keyframes longEndAnimation {
    0% { transform: scaleY(1); }
    50% { transform: scaleY(0.9); }
    100% { transform: scaleY(1); }
  }
  
  @keyframes longEndLinesAnimation {
    0% { transform: translateY(0); }
    50% { transform: translateY(1.5px); }
    100% { transform: translateY(0); }
  }
  
  @keyframes shortEndAnimation {
    0% { transform: scaleY(1); }
    50% { transform: scaleY(1.1); }
    100% { transform: scaleY(1); }
  }
  
  .long-end.animate {
    animation: longEndAnimation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .long-end-lines.animate {
    animation: longEndLinesAnimation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  .short-end.animate {
    animation: shortEndAnimation 0.6s cubic-bezier(0.45, 0, 0.55, 1);
  }
  
  /* Docs Icon Animation */
  .doc-line-1 {
    transform: translateY(0);
    opacity: 1;
  }
  
  .doc-line-2 {
    transform: translateY(0);
  }
  
  .doc-line-3 {
    transform: translateY(0);
  }
  
  .doc-line-4 {
    transform-origin: left center;
    opacity: 0;
  }
  
  .pen {
    transform-origin: center;
    transform: translate(0) rotate(0deg);
  }
  
  @keyframes docLine1Animation {
    0% { transform: translateY(0); opacity: 1; }
    100% { transform: translateY(-2px); opacity: 0; }
  }
  
  @keyframes docLine2Animation {
    0% { transform: translateY(0); }
    100% { transform: translateY(-3.33px); }
  }
  
  @keyframes docLine3Animation {
    0% { transform: translateY(0); }
    100% { transform: translateY(-3.33px); }
  }
  
  @keyframes docLine4Animation {
    0% { opacity: 0; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
  
  @keyframes penAnimation {
    0% { transform: translate(0) rotate(0deg); }
    25% { transform: translate(-2px) rotate(20deg); }
    75% { transform: translate(2px) rotate(0deg); }
    100% { transform: translate(0) rotate(0deg); }
  }
  
  .doc-line-1.animate {
    animation: docLine1Animation 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  
  .doc-line-2.animate {
    animation: docLine2Animation 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  
  .doc-line-3.animate {
    animation: docLine3Animation 0.6s cubic-bezier(0.4, 0, 0.2, 1) forwards;
  }
  
  .doc-line-4.animate {
    animation: docLine4Animation 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
  
  .pen.animate {
    animation: penAnimation 0.6s cubic-bezier(0.4, 0, 0.2, 1);
  }

.reward-icon {
    transform-origin: center;
}
  
.reward-icon.animate {
    animation: rewardShake 0.5s ease-in-out;
}
  
@keyframes rewardShake {
    0% {
      transform: rotate(0deg);
    }
    25% {
      transform: rotate(-15deg);
    }
    75% {
      transform: rotate(15deg);
    }
    100% {
      transform: rotate(0deg);
    }
}

.settings-icon {
  transform-origin: center;
}

.settings-icon.animate .settings-outer {
  transform-origin: 10px 10px; /* Center point of the 20x20 viewBox */
  animation: settingsRotate 0.5s ease-in-out;
}

@keyframes settingsRotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(30deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

.exit-icon {
  transform-origin: center;
}

.exit-icon.animate .exit-arrow {
  animation: arrowJiggle 0.5s ease-in-out;
}

@keyframes arrowJiggle {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(2px);
  }
  75% {
    transform: translateX(-1px);
  }
  100% {
    transform: translateX(0);
  }
}