.employer-dashboard-wrapper {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.employer-dashboard-container {
  width: 100%;
  height: 100%;
  display: flex;
  background: white;
}

.employer-dashboard-main-container {
  flex: 1;
  height: 100vh;
  overflow-y: auto;
  background: white;
}

.employer-dashboard-sidebar {
  height: 100vh;
  position: sticky;
  top: 0;
  left: 0;
  align-self: stretch;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  display: inline-flex;
  background-color: #17332D;
  background-image: url('../assets/images/boreal-grain.png');
  background-blend-mode: normal;
  background-size: contain;
  background-repeat: repeat;
}

.employer-dashboard-logo-section {
  align-self: stretch;
  padding: 16px 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.employer-dashboard-logo-wrapper {
  height: 32px;
  padding: 0 8px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: flex;
}

.employer-dashboard-logo-inner {
  flex: 1 1 0;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.employer-dashboard-logo-icon {
  width: 16px;
  height: 12px;
  background: transparent;
  color: #F8F8F5;
}

.employer-dashboard-nav-menu {
  width: 3rem;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.employer-dashboard-nav-item {
  padding: 10px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.employer-dashboard-nav-icon {
  width: 16px;
  height: 16px;
  position: relative;
}

.employer-dashboard-nav-icon-circle {
  width: 16px;
  height: 16px;
  position: absolute;
  border-radius: 9999px;
  border: 1px white solid;
}

.employer-dashboard-nav-icon-bar {
  width: 4px;
  height: 8.5px;
  position: absolute;
  background: white;
  left: 6px;
  top: 4px;
}

.employer-dashboard-content {
  flex: 1;
  height: 100%;
  background: white;
  flex-direction: column;
  display: flex;
}

.employer-dashboard-header {
  align-self: stretch;
  padding: 80px 20px 20px;
  background: #EEEEEE;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.employer-dashboard-welcome-text {
  color: black;
  font-size: 40px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  word-wrap: break-word;
}

  .employer-dashboard-content-area {
    flex: 1;
    padding: 20px;
    background: #E9E7E6;
    display: flex;
    gap: 20px;
  }

  .employer-dashboard-balance-card {
    width: 400px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.70);
    box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    overflow: hidden;
    backdrop-filter: blur(30px);
    display: flex;
  }

  .employer-dashboard-balance-info {
    width: 100%;
    flex-direction: column;
    gap: 10px;
    display: flex;
  }

  .employer-dashboard-balance-text {
    color: black;
    font-size: 48px;
    font-family: var(--font-medium);
    font-weight: var(--font-medium);
    word-wrap: break-word;
  }

  .employer-dashboard-profit-text {
    color: #34A853;
    font-size: 18px;
    font-family: 'Futura';
    font-weight: var(--font-medium);
    word-wrap: break-word;
  }

  .employer-dashboard-right-column {
    flex: 1;
    flex-direction: column;
    gap: 20px;
    display: flex;
  }

  .employer-dashboard-card {
    width: 100%;
    padding: 20px;
    background: rgba(255, 255, 255, 0.70);
    box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    overflow: hidden;
    backdrop-filter: blur(30px);
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .employer-dashboard-card-header {
    width: 100%;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

.employer-dashboard-card-title {
  color: black;
  font-size: 20px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  word-wrap: break-word;
}

.employer-dashboard-card-line {
  width: 18px;
  height: 11px;
}

.employer-dashboard-progress-bar {
  align-self: stretch;
  height: 5px;
  background: #34A853;
  border-radius: 2.5px;
}

.employer-dashboard-balance-icon {
  width: 16px;
  height: 12px;
  margin-left: 8px;
}

.connect-payroll-main {
  flex: 1;
  height: 100%;
  background: white;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.connect-payroll-main-header {
  align-self: stretch;
  padding: 80px 20px 20px;
  background: #EEEEEE;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.connect-payroll-title {
  color: black;
  font-size: 40px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  word-wrap: break-word;
}

.connect-payroll-details {
  align-self: stretch;
  flex: 1 1 0;
  background: #E9E7E6;
}

.employer-dashboard-nav-label {
  white-space: nowrap;
  opacity: 0;
  transition: opacity 0.2s ease;
}

.expanded .employer-dashboard-nav-label {
  opacity: 1;
}

.employer-dashboard-main {
  width: 100%;
  height: 100%;
}

.employer-dashboard-main-container {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
}

.employer-dashboard-header-section {
  align-self: stretch;
  padding: 80px 48px 20px 48px;
  background: #EEEEEE;
}

.employer-dashboard-welcome-text {
  color: black;
  font-size: var(--text-3xl);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: var(--text-offset);
}

.employer-dashboard-content-grid {
  align-self: stretch;
  flex: 1;
  padding: 3rem;
  background: #E9E7E6;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
}

.employer-dashboard-card {
  width: 100%;
  min-height: 300px;
  padding: 16px;
  background: rgba(255, 255, 255, 0.70);
  box-shadow: 1px 1px 4px -1px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  backdrop-filter: blur(30px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.employer-dashboard-card-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.employer-dashboard-card-header {
  height: 36px;
}

.employer-dashboard-card-title {
  color: black;
  font-size: var(--text-2xl);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: var(--text-offset);
}

.employer-dashboard-enrollment-stats {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.employer-dashboard-stats-header {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: var(--text-offset);
}

.employer-dashboard-progress-bar {
  height: 4rem;
  width: 100%;
  display: flex;
}

.employer-dashboard-progress-filled {
  background: #315E54;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
}

.employer-dashboard-progress-text {
  color: white;
  font-size: var(--text-xl);
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  transform: var(--text-offset);
}

.employer-dashboard-progress-remaining {
  background: #FFB700;
  width: 159px;
  height: 100%;
}

.employer-dashboard-card-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 5px;
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.employer-dashboard-arrow-icon {
  width: 10px;
  height: 6px;
}

.employer-dashboard-funding-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.employer-dashboard-funding-details {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.employer-dashboard-funding-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #17332D;
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.employer-dashboard-funding-box {
  padding: 12px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.employer-dashboard-funding-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.employer-dashboard-funding-label {
  width: 159px;
  color: #656565;
  font-size: var(--text-sm);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.employer-dashboard-funding-sublabel {
  font-size: var(--text-xs);
  color: #656565;
}

.employer-dashboard-funding-divider {
  height: 1px;
  background: #656565;
  margin: 5px 0;
}

.employer-dashboard-savings-content {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.employer-dashboard-savings-bar {
  height: 4rem;
  width: 100%;
  display: flex;
}

.employer-dashboard-savings-primary {
  background: #17332D;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 10px;
}

.employer-dashboard-savings-secondary {
  background: #315E54;
  height: 100%;
}

.employer-dashboard-savings-tertiary {
  background: #FFB700;
  height: 100%;
}

.employer-dashboard-savings-text {
  color: white;
  font-size: var(--text-xl);
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
}

.employer-dashboard-savings-legend {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

.employer-dashboard-legend-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.employer-dashboard-legend-dot {
  width: 10px;
  height: 10px;
}

.employer-dashboard-legend-dot.primary {
  background: #315E54;
}

.employer-dashboard-legend-dot.secondary {
  background: #FFB700;
}

.employer-dashboard-legend-text {
  color: black;
  font-size: var(--text-xs);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.employer-dashboard-notifications-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.employer-dashboard-notifications-list {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.employer-dashboard-notification-item {
  width: 100%;
  padding: 12px;
  background: white;
  border-radius: 10px;
  border: 1px solid #656565;
  display: flex;
  align-items: center;
  gap: 16px;
}

.employer-dashboard-notification-icon {
  width: 32px;
  height: 32px;
  padding: 10px;
  background: #656565;
  border-radius: 5px;
}

.employer-dashboard-notification-text {
  flex: 1;
  color: #656565;
  font-size: var(--text-sm);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.employer-dashboard-notification-subtext {
  font-size: var(--text-xs);
  color: #656565;
}

.employer-dashboard-savings-stats {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.employer-dashboard-savings-stats .employer-dashboard-stats-header {
  display: flex;
  justify-content: space-between;
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: var(--text-offset);
}

/* Funding amount styles */
.employer-dashboard-funding-amount {
  color: #656565;
  font-size: var(--text-sm);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

/* Legend wrapper for savings card */
.employer-dashboard-savings-legend-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

/* Notification wrapper */
.employer-dashboard-notification-wrapper {
  height: 25px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

/* Notification content */
.employer-dashboard-notification-content {
  width: 249px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1024px) {
  .employer-dashboard-content-grid {
    grid-template-columns: 1fr;
  }
}

.employer-dashboard-savings-visualization {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.employer-dashboard-footer-text {
  color: black;
  font-size: var(--text-base);
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  transform: var(--text-offset);
  text-align: right;
}

.employer-dashboard-loading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.employer-dashboard-loading-spinner {
  width: 40px;
  height: 40px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #315E54;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
