.signup-selector-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background-color: #f5f5f5;
}

.signup-selector-content {
  text-align: center;
  max-width: 800px;
  width: 100%;
}

.signup-selector-options {
  display: flex;
  gap: 30px;
  margin-top: 40px;
  justify-content: center;
}

.signup-selector-card {
  background: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  flex: 1;
  max-width: 300px;
}

.signup-selector-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.signup-selector-icon {
  font-size: 48px;
  margin-bottom: 20px;
}

.signup-selector-card h2 {
  margin: 0 0 10px;
  color: #333;
}

.signup-selector-card p {
  margin: 0;
  color: #666;
} 