/* First define CSS custom properties */
:root {
  --system-fonts: -apple-system, BlinkMacSystemFont, 'Segoe UI', 
    Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue', sans-serif;
  --font-primary: 'TWK Lausanne 350';
  --font-medium: 'TWK Lausanne 500';
  --font-normal: 350;
  --font-medium-weight: 500;
  --text-offset: 2px;
}

/* Monument Grotesk Font Faces */
@font-face {
  font-family: 'Monument Grotesk';
  src: url('../assets/fonts/MonumentGrotesk-Regular.woff2') format('woff2'),
       url('../assets/fonts/MonumentGrotesk-Regular.woff') format('woff');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src: url('../assets/fonts/MonumentGrotesk-Medium.woff2') format('woff2'),
       url('../assets/fonts/MonumentGrotesk-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src: url('../assets/fonts/MonumentGrotesk-Bold.woff2') format('woff2'),
       url('../assets/fonts/MonumentGrotesk-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Monument Grotesk';
  src: url('../assets/fonts/MonumentGrotesk-RegularItalic.woff2') format('woff2'),
       url('../assets/fonts/MonumentGrotesk-RegularItalic.woff') format('woff');
  font-weight: 350;
  font-style: italic;
  font-display: swap;
}

/* TWK Lausanne Font Faces */
@font-face {
  font-family: 'TWK Lausanne 350';
  src: url('../assets/fonts/TWKLausanne-350.woff2') format('woff2'),
       url('../assets/fonts/TWKLausanne-350.woff') format('woff');
  font-weight: 350;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWK Lausanne 350';
  src: url('../assets/fonts/TWKLausanne-350Italic.woff2') format('woff2'),
       url('../assets/fonts/TWKLausanne-350Italic.woff') format('woff');
  font-weight: 350;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'TWK Lausanne 500';
  src: url('../assets/fonts/TWKLausanne-500.woff2') format('woff2'),
       url('../assets/fonts/TWKLausanne-500.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'TWK Lausanne 500';
  src: url('../assets/fonts/TWKLausanne-500Italic.woff2') format('woff2'),
       url('../assets/fonts/TWKLausanne-500Italic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

/* Base Styles */
body {
  font-family: var(--font-primary), var(--system-fonts);
}

/* Font Family Utilities */
:root {
  --font-primary-light: var(--font-primary), var(--system-fonts);
  --font-primary-medium: var(--font-medium), var(--system-fonts);
  --font-weight-light: var(--font-normal);
  --font-weight-medium: var(--font-medium-weight);
}

/* TWK Lausanne Adjustment */
[style*="TWK Lausanne 350"], 
*[style*="TWK Lausanne 350"],
[class*="TWK Lausanne 350"],
*[class*="TWK Lausanne 350"] {
    transform: translateY(2px);
}
