.employer-signup-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #17332D;
  background-image: url('../assets/images/boreal-grain.png');
  background-blend-mode: normal;
  background-size: contain;
  background-repeat: repeat;
}

.employer-signup-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(13, 13, 10, 0.10);
  height: 100vh;
  width: auto;
  flex-shrink: 0;
}

.employer-signup-logo-section {
  align-self: stretch;
  padding: 16px 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.employer-signup-logo-wrapper {
  height: 32px;
  padding: 0 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.employer-signup-logo-inner {
  flex: 1 1 0;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.employer-signup-logo-icon {
  width: 16px;
  height: 12px;
  color: #F8F8F5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employer-signup-logo-icon svg {
  width: 100%;
  height: 100%;
}

.employer-signup-nav-menu {
  width: 209px;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.employer-signup-nav-item {
  padding: 4px 8px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.employer-signup-nav-item-inner {
  padding: 0 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.employer-signup-nav-icon {
  width: 12px;
  height: 12px;
  position: relative;
}

.employer-signup-nav-icon-box {
  width: 12px;
  height: 12px;
  color: #F8F8F5;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  position: relative;
}

.employer-signup-nav-icon-box svg {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.employer-signup-nav-icon-box .checked-icon {
  transform: scale(0);
  opacity: 0;
}

.employer-signup-nav-icon-box .unchecked-icon {
  transform: scale(1);
  opacity: 1;
}

.employer-signup-nav-item.active .employer-signup-nav-icon-box .checked-icon {
  transform: scale(1);
  opacity: 1;
}

.employer-signup-nav-item.active .employer-signup-nav-icon-box .unchecked-icon {
  transform: scale(0);
  opacity: 0;
}

.employer-signup-nav-text {
  width: 155px;
  color: white;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 20px;
  word-wrap: break-word;
}

.employer-signup-signout-section {
  align-self: stretch;
  padding: 4px 0;
  background: rgba(248, 248, 245, 0.05);
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.employer-signup-signout-wrapper {
  flex: 1 1 0;
  padding: 4px 8px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.employer-signup-signout-content {
  align-self: stretch;
  padding: 0 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.employer-signup-signout-icon {
  width: 12px;
  height: 12px;
  color: #F8F8F5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.employer-signup-icon-line-1 {
  width: 3px;
  height: 9px;
  left: 1.5px;
  top: 1.5px;
  position: absolute;
  border: 1px #F8F8F5 solid;
}

.employer-signup-icon-line-2 {
  width: 2.5px;
  height: 5px;
  left: 8px;
  top: 3.5px;
  position: absolute;
  border: 1px #F8F8F5 solid;
}

.employer-signup-icon-line-3 {
  width: 6px;
  height: 0;
  left: 4.5px;
  top: 6px;
  position: absolute;
  border: 1px #F8F8F5 solid;
}

.employer-signup-signout-text {
  color: white;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 20px;
  word-wrap: break-word;
}

.employer-signup-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  max-width: 50rem;
  width: 100%;
  margin-right: auto;
  margin-left: 0;
  overflow-y: auto;
  height: 100vh;
}

.employer-signup-header {
  align-self: stretch;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.employer-signup-title {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 40px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  word-wrap: break-word;
}

.employer-signup-spacer {
  align-self: stretch;
  height: 8px;
}

.employer-signup-form-section {
  flex: 1;
  padding: 2rem 0;
}

.employer-signup-form-header {
  align-self: stretch;
  padding-bottom: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.employer-signup-subtitle {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 16px;
  word-wrap: break-word;
}

.employer-signup-section-title-wrapper {
  align-self: stretch;
  padding: 20px 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.employer-signup-section-title {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 32px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 0px;
  width: 100%;
}

.employer-signup-section-description {
  align-self: stretch;
  padding-bottom: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  color: #0D0D0A;
  opacity: 0.7;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  margin-bottom: 24px;
  margin-top: 10px;
  line-height: 1.5;
}

.employer-signup-description-text {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  word-wrap: break-word;
}

.employer-signup-input-field {
  padding: 0px 0;
  margin-bottom: 0px;
  width: 100%;
}

.employer-signup-input-wrapper {
  align-self: stretch;
  position: relative;
  width: 100%;
  padding: 7px 0 7px 12px;
  background: rgba(255, 255, 255, 0.10);
  border: 1px solid #0D0D0A;
  height: 54px;
  box-sizing: border-box;
  margin-bottom: 0px;
}

.employer-signup-input-container {
  position: relative;
  width: 100%;
}

.employer-signup-input,
.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 16px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-employerearance: none;
  -moz-employerearance: none;
  appearance: none;
  caret-color: #0D0D0A;
  box-sizing: border-box;
}

.employer-signup-input-label {
  position: absolute;
  left: 0;
  top: 57%;
  transform: translateY(-50%);
  color: #0D0D0A;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  pointer-events: none;
  transition: 0.2s ease all;
  opacity: 0.7;
}

.employer-signup-input-helper {
  font-size: 10px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  color: #0D0D0A;
  opacity: 0.7;
  padding-top: 2px;
}

/* Remove arrows from number input */
.employer-signup-input-number::-webkit-outer-spin-button,
.employer-signup-input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.employer-signup-input-number[type=number] {
  -moz-appearance: textfield;
  appearance: textfield;
}

.employer-signup-input:focus ~ .employer-signup-input-label,
.employer-signup-input:not(:placeholder-shown) ~ .employer-signup-input-label,
.employer-signup-input-wrapper.focused .employer-signup-input-label,
.employer-signup-input-wrapper .employer-signup-input-label-float,
.react-datepicker__input-container input:focus ~ .employer-signup-input-label,
.react-datepicker__input-container input:not(:placeholder-shown) ~ .employer-signup-input-label {
  top: 4px !important;
  left: 0;
  font-size: 12px;
  opacity: 0.7;
  transform: none;
}

.employer-signup-input:focus {
  background: transparent;
  border: none;
}

.employer-signup-button-container {
  padding: 24px 0;
  background: transparent;
  display: flex;
  justify-content: flex-start;
}

.employer-signup-submit-button {
  height: 38px;
  padding: 0 12px;
  background: #315E54;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #F8F8F5;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  border: none;
  cursor: pointer;
}

.employer-signup-preview-section {
  width: 40rem;
  background: rgba(248, 248, 245, 0.05);
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: auto;
}

.employer-signup-preview-container {
  max-width: 100%;
  padding: 2rem;
}

.employer-signup-preview-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.employer-signup-preview-quote {
  color: #0D0D0A;
  font-size: 24px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 100%;
  max-width: 32rem;
  padding-bottom: 2rem;
  padding-top: 1rem;

}

.employer-signup-preview-author {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.employer-signup-preview-name {
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.employer-signup-preview-title,
.employer-signup-preview-company {
  color: #0D0D0A;
  opacity: 0.7;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.employer-signup-nav-item.active {
  background: rgba(248, 248, 245, 0.05);
  width: max-content;
}

.employer-signup-nav-item.active .employer-signup-nav-text {
  opacity: 1;
}

.employer-signup-nav-item.active .employer-signup-nav-icon-box {
  opacity: 1;
}

.employer-signup-nav-item:hover {
  background: rgba(248, 248, 245, 0.02);
  cursor: pointer;
}

.employer-signup-submit-button:hover {
  background: #3a7267;
}

.employer-signup-input-wrapper {
  margin-bottom: 0px;
}

.employer-signup-input:focus {
  background: transparent;
  border: none;
}

.employer-signup-nav-item,
.employer-signup-submit-button {
  transition: background-color 0.2s ease;
}

.employer-signup-nav-item.completed:not(.active) {
  background: rgba(248, 248, 245, 0.02);
}

.employer-signup-nav-item.completed:not(.active):hover {
  background: rgba(248, 248, 245, 0.05);
}

.employer-signup-nav-item.completed .employer-signup-nav-icon-box,
.employer-signup-nav-item.completed .employer-signup-nav-text {
  opacity: 0.9;
}

.employer-signup-nav-item:not(.completed):not(.active) {
  opacity: 0.5;
  pointer-events: none;
}

.employer-signup-input-wrapper:focus-within {
  background: rgba(248, 248, 245, 0.05);
}

/* Add these styles for the date picker */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
  height: 100%;
  background: transparent;
}

/* Style the dropdown arrows */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #17332D !important;
  border: 1px solid #0D0D0A !important;
}

.react-datepicker__month-option,
.react-datepicker__year-option {
  color: #0D0D0A !important;
}

.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

/* Make sure the datepicker input matches our custom input styling */
.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Add a new class for floating label with datepicker */
.employer-signup-input-label-float {
  top: 8px !important;
  font-size: 12px !important;
  opacity: 0.7 !important;
}

/* Ensure the datepicker input container doesn't interfere with label */
.react-datepicker__input-container {
  background: transparent;
}

/* Keep existing styles and add/modify these */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.employer-signup-input-wrapper.focused .employer-signup-input-label,
.react-datepicker__input-container input:not(:placeholder-shown) ~ .employer-signup-input-label {
  top: 4px !important;
  left: 0;
  font-size: 12px;
  opacity: 0.7;
}

/* Calendar styling */
.react-datepicker {
  background-color: #17332D !important;
  border: 1px solid #0D0D0A !important;
  font-family: var(--font-primary) !important;
  color: #0D0D0A !important;
}

.react-datepicker__header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-bottom: 1px solid #0D0D0A !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: #0D0D0A !important;
}

.react-datepicker__day:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #315E54 !important;
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #17332D !important;
  border: 1px solid #0D0D0A !important;
}

.react-datepicker__month-option,
.react-datepicker__year-option {
  color: #F8F8F5 !important;
}

.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-datepicker__input-container {
  background: transparent;
}

.react-datepicker__triangle {
  display: none;
}

.employer-signup-input-wrapper.focused .employer-signup-input-label,
.employer-signup-input-wrapper .employer-signup-input-label-float {
  top: 8px;
  font-size: 12px;
  opacity: 0.7;
}

/* Add this to ensure the date input text aligns with other inputs */
.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Add these new styles */
.employer-signup-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

.employer-signup-input-field {
  width: 100%; /* Default full width */
}

.employer-signup-input-field.half-width {
  width: 50%;
}

.employer-signup-input-field.third-width {
  width: calc((100% - 32px) / 3); /* Accounts for the gaps between fields */
}

.employer-signup-form-group {
  width: 100%;
  margin-bottom: 32px;
}

.employer-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
}

.employer-signup-input-row:last-child,
.employer-signup-input-field:last-child {
  margin-bottom: 0;
}

/* Update form group spacing */
.employer-signup-form-group {
  width: 100%;
  margin-bottom: 32px;
}

.employer-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
}

/* Add spacing between ALL input fields */
.employer-signup-input-field {
  margin-bottom: 16px; /* Space between input fields */
  width: 100%;
}

/* Add spacing between input rows */
.employer-signup-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

/* Remove margin from last elements to prevent extra spacing */
.employer-signup-form-group:last-child {
  margin-bottom: 0;
}

.employer-signup-input-row:last-child {
  margin-bottom: 0;
}

/* Only remove bottom margin from input fields that are inside input rows */
.employer-signup-input-row .employer-signup-input-field {
  margin-bottom: 0;
}

/* Ensure the main content area has proper spacing */
.employer-signup-main-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 5rem;
  overflow: hidden;
}

/* Question Container */
.employer-signup-radio-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid rgba(248, 248, 245, 0.1);
  width: 100%;
}

.employer-signup-radio-question:last-child {
  border-bottom: none;
}

/* Question Text */
.employer-signup-question-text {
  color: #0D0D0A;  /* Changed to white */
  font-family: var(--font-primary);
  font-size: 14px;
  font-weight: var(--font-normal);
  line-height: 1.4;
  flex: 1;
  padding-right: 24px;
}

/* Radio Options Container */
.employer-signup-radio-options {
  display: flex;
  gap: 24px;
  min-width: 160px;  /* Fixed width for the options column */
  justify-content: flex-end;
}

/* Radio Label */
.employer-signup-radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: 14px;
  color: #315E54;  /* Changed to white */
  white-space: nowrap;
}

/* Custom Radio Button */
.employer-signup-radio-label input[type="radio"] {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(248, 248, 245, 0.4);  /* Lighter border */
  background: transparent;
}

/* Radio Button Checked State */
.employer-signup-radio-label input[type="radio"]:checked {
  border-color: #0D0D0A;
}

/* Custom Radio Button Dot */
.employer-signup-radio-label input[type="radio"]:checked::after {
  background-color: #0D0D0A;  /* White dot */
}

/* Hover State */
.employer-signup-radio-label:hover input[type="radio"]:not(:checked) {
  border-color: #0D0D0A;
}

/* Form Group for Radio Questions */
.employer-signup-form-group {
  padding: 0px 0;  /* Keep spacing but remove background */
  margin-top: 24px;
}

.employer-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(248, 248, 245, 0.1);
}

/* Special styling for health insurance questions (case 3) */
.employer-signup-form-group.health-questions {
  background: rgba(248, 248, 245, 0.05);
  border-radius: 8px;
  padding: 24px;
}

/* Custom scrollbar styling */
.employer-signup-form-section::-webkit-scrollbar {
  width: 8px;
}

.employer-signup-form-section::-webkit-scrollbar-track {
  background: rgba(248, 248, 245, 0.05);
}

.employer-signup-form-section::-webkit-scrollbar-thumb {
  background: rgba(248, 248, 245, 0.2);
  border-radius: 4px;
}

.employer-signup-form-section::-webkit-scrollbar-thumb:hover {
  background: rgba(248, 248, 245, 0.3);
}

/* Radio button styling */
.employer-signup-radio-options input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #0D0D0A;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}

.employer-signup-radio-options input[type="radio"]:checked {
  background-color: transparent;
  border-color: #0D0D0A;
}

.employer-signup-radio-options input[type="radio"]:checked::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #0D0D0A;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.employer-signup-radio-options input[type="radio"]:hover {
  border-color: #315E54;
}

/* Custom scrollbar for main container */
.employer-signup-container::-webkit-scrollbar {
  width: 8px;
}

.employer-signup-container::-webkit-scrollbar-track {
  background: rgba(248, 248, 245, 0.05);
}

.employer-signup-container::-webkit-scrollbar-thumb {
  background: rgba(248, 248, 245, 0.2);
  border-radius: 4px;
}

.employer-signup-container::-webkit-scrollbar-thumb:hover {
  background: rgba(248, 248, 245, 0.3);
}

/* New scrollable container */
.employer-signup-scrollable-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
  background: #F8F8F5;
}


/* Main content */
.employer-signup-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  max-width: 50rem;
  width: 100%;
  height: fit-content;
  margin-right: auto;
  margin-left: 0;
}

/* Preview section */
.employer-signup-preview-section {
  width: 40rem;
  background: #f4f3ef;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: 100vh;
}

.employer-signup-finch-container {
  margin: 24px 0;
  padding: 32px;
  background: #F4F3EF;
  border-radius: 8px;
  text-align: center;
  font-weight: var(--font-medium);
}

.employer-signup-skip-section {
  margin-top: 24px;
  text-align: left;
}


.employer-signup-skip-button:hover {
  text-decoration: underline;
}

.svalbard-link {
  color: #315E54;
  text-decoration: none;
}

.svalbard-link:hover {
  text-decoration: underline;
}

.connect-bank-button {
  width: 100%;
  padding: 12px;
  background-color: #315E54;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: var(--font-medium);
  cursor: pointer;
  margin: 24px 0;
}

.connect-bank-button:hover {
  background-color: #315E54;
}

.plaid-security-note {
  text-align: center;
  color: #6B7280;
  font-size: 14px;
  margin-bottom: 24px;
}

.lock-icon {
  margin-right: 6px;
}

.employer-signup-legal-text {
  color: #6B7280;
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.connect-payroll-main {
  margin: 24px 0;
}

.connect-payroll-main-header {
  margin-bottom: 24px;
}

.connect-payroll-title {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
}

.connect-payroll-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

/* Remove the old finch-connect-button styles and use connect-bank-button instead */
.connect-bank-button {
  width: 100%;
  padding: 12px;
  background-color: #315E54;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: var(--font-medium);
  cursor: pointer;
  margin: 24px 0;
}

.connect-bank-button:hover {
  background-color: #3a7267;
}