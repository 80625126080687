.indiv-signup-container {
  display: flex;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  background-color: #17332D;
  background-image: url('../assets/images/boreal-grain.png');
  background-blend-mode: normal;
  background-size: contain;
  background-repeat: repeat;
}

.indiv-signup-sidebar {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: rgba(13, 13, 10, 0.10);
  height: 100vh;
  width: auto;
  flex-shrink: 0;
}

.indiv-signup-logo-section {
  align-self: stretch;
  padding: 16px 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.indiv-signup-logo-wrapper {
  height: 32px;
  padding: 0 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.indiv-signup-logo-inner {
  flex: 1 1 0;
  height: 32px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.indiv-signup-logo-icon {
  width: 16px;
  height: 12px;
  color: #F8F8F5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indiv-signup-logo-icon svg {
  width: 100%;
  height: 100%;
}

.indiv-signup-nav-menu {
  width: 209px;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.indiv-signup-nav-item {
  padding: 4px 8px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.indiv-signup-nav-item-inner {
  padding: 0 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: flex;
}

.indiv-signup-nav-icon {
  width: 12px;
  height: 12px;
  position: relative;
}

.indiv-signup-nav-icon-box {
  width: 12px;
  height: 12px;
  color: #F8F8F5;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  transition: opacity 0.2s ease;
  position: relative;
}

.indiv-signup-nav-icon-box svg {
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.2s ease, opacity 0.2s ease;
}

.indiv-signup-nav-icon-box .checked-icon {
  transform: scale(0);
  opacity: 0;
}

.indiv-signup-nav-icon-box .unchecked-icon {
  transform: scale(1);
  opacity: 1;
}

.indiv-signup-nav-item.active .indiv-signup-nav-icon-box .checked-icon {
  transform: scale(1);
  opacity: 1;
}

.indiv-signup-nav-item.active .indiv-signup-nav-icon-box .unchecked-icon {
  transform: scale(0);
  opacity: 0;
}

.indiv-signup-nav-text {
  width: 155px;
  color: white;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 20px;
  word-wrap: break-word;
}

.indiv-signup-signout-section {
  align-self: stretch;
  padding: 4px 0;
  background: rgba(248, 248, 245, 0.05);
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.indiv-signup-signout-wrapper {
  flex: 1 1 0;
  padding: 4px 8px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.indiv-signup-signout-content {
  align-self: stretch;
  padding: 0 8px;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  display: inline-flex;
}

.indiv-signup-signout-icon {
  width: 12px;
  height: 12px;
  color: #F8F8F5;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indiv-signup-icon-line-1 {
  width: 3px;
  height: 9px;
  left: 1.5px;
  top: 1.5px;
  position: absolute;
  border: 1px #F8F8F5 solid;
}

.indiv-signup-icon-line-2 {
  width: 2.5px;
  height: 5px;
  left: 8px;
  top: 3.5px;
  position: absolute;
  border: 1px #F8F8F5 solid;
}

.indiv-signup-icon-line-3 {
  width: 6px;
  height: 0;
  left: 4.5px;
  top: 6px;
  position: absolute;
  border: 1px #F8F8F5 solid;
}

.indiv-signup-signout-text {
  color: white;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 20px;
  word-wrap: break-word;
}

.indiv-signup-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  max-width: 50rem;
  width: 100%;
  margin-right: auto;
  margin-left: 0;
  overflow-y: auto;
  height: 100vh;
}

.indiv-signup-header {
  align-self: stretch;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.indiv-signup-title {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 40px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  word-wrap: break-word;
}

.indiv-signup-spacer {
  align-self: stretch;
  height: 8px;
}

.indiv-signup-form-section {
  flex: 1;
  padding: 2rem 0;
}

.indiv-signup-form-header {
  align-self: stretch;
  padding-bottom: 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  display: inline-flex;
}

.indiv-signup-subtitle {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 16px;
  word-wrap: break-word;
}

.indiv-signup-section-title-wrapper {
  align-self: stretch;
  padding: 20px 0;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
}

.indiv-signup-section-title {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 40px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 0px;
}

.indiv-signup-section-description {
  align-self: stretch;
  padding-bottom: 20px;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  display: inline-flex;
  color: #0D0D0A;
  opacity: 0.7;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  margin-bottom: 24px;
}

.indiv-signup-description-text {
  flex: 1 1 0;
  color: #0D0D0A;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  word-wrap: break-word;
}

.indiv-signup-input-field {
  padding: 0px 0;
  margin-bottom: 0px;
  width: 100%;
}

.indiv-signup-input-wrapper {
  align-self: stretch;
  position: relative;
  width: 100%;
  padding: 7px 0 7px 12px;
  background: rgba(255, 255, 255, 0.10);
  border: 1px solid #0D0D0A;
  height: 54px;
  box-sizing: border-box;
  margin-bottom: 0px;
}

.indiv-signup-input-container {
  position: relative;
  width: 100%;
}

.indiv-signup-input,
.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 16px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  caret-color: #0D0D0A;
  box-sizing: border-box;
}

.indiv-signup-input-label {
  position: absolute;
  left: 0;
  top: 57%;
  transform: translateY(-50%);
  color: #0D0D0A;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  pointer-events: none;
  transition: 0.2s ease all;
  opacity: 0.7;
}

.indiv-signup-input:focus ~ .indiv-signup-input-label,
.indiv-signup-input:not(:placeholder-shown) ~ .indiv-signup-input-label,
.indiv-signup-input-wrapper.focused .indiv-signup-input-label,
.indiv-signup-input-wrapper .indiv-signup-input-label-float,
.react-datepicker__input-container input:focus ~ .indiv-signup-input-label,
.react-datepicker__input-container input:not(:placeholder-shown) ~ .indiv-signup-input-label {
  top: 4px !important;
  left: 0;
  font-size: 12px;
  opacity: 0.7;
  transform: none;
}

.indiv-signup-input:focus {
  background: transparent;
  border: none;
}

.indiv-signup-button-container {
  padding: 24px 0;
  background: transparent;
  display: flex;
  justify-content: flex-start;
}

.indiv-signup-submit-button {
  height: 38px;
  padding: 0 12px;
  background: #315E54;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #F8F8F5;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  border: none;
  cursor: pointer;
}

.indiv-signup-preview-section {
  width: 40rem;
  background: rgba(248, 248, 245, 0.05);
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: auto;
}

.indiv-signup-preview-container {
  max-width: 100%;
  padding: 2rem;
}

.indiv-signup-preview-image {
  width: 100%;
  height: auto;
  border-radius: 4px;
}

.indiv-signup-preview-quote {
  color: #0D0D0A;
  font-size: 24px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 100%;
  max-width: 32rem;
  padding-bottom: 2rem;
  padding-top: 1rem;

}

.indiv-signup-preview-author {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.indiv-signup-preview-name {
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.indiv-signup-preview-title,
.indiv-signup-preview-company {
  color: #0D0D0A;
  opacity: 0.7;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

.indiv-signup-nav-item.active {
  background: rgba(248, 248, 245, 0.05);
  width: max-content;
}

.indiv-signup-nav-item.active .indiv-signup-nav-text {
  opacity: 1;
}

.indiv-signup-nav-item.active .indiv-signup-nav-icon-box {
  opacity: 1;
}

.indiv-signup-nav-item:hover {
  background: rgba(248, 248, 245, 0.02);
  cursor: pointer;
}

.indiv-signup-submit-button:hover {
  background: #3a7267;
}

.indiv-signup-input-wrapper {
  margin-bottom: 0px;
}

.indiv-signup-input:focus {
  background: transparent;
  border: none;
}

.indiv-signup-nav-item,
.indiv-signup-submit-button {
  transition: background-color 0.2s ease;
}

.indiv-signup-nav-item.completed:not(.active) {
  background: rgba(248, 248, 245, 0.02);
}

.indiv-signup-nav-item.completed:not(.active):hover {
  background: rgba(248, 248, 245, 0.05);
}

.indiv-signup-nav-item.completed .indiv-signup-nav-icon-box,
.indiv-signup-nav-item.completed .indiv-signup-nav-text {
  opacity: 0.9;
}

.indiv-signup-nav-item:not(.completed):not(.active) {
  opacity: 0.5;
  pointer-events: none;
}

.indiv-signup-input-wrapper:focus-within {
  background: rgba(248, 248, 245, 0.05);
}

/* Add these styles for the date picker */
.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
  height: 100%;
  background: transparent;
}

/* Style the dropdown arrows */
.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #17332D !important;
  border: 1px solid #0D0D0A !important;
}

.react-datepicker__month-option,
.react-datepicker__year-option {
  color: #0D0D0A !important;
}

.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

/* Make sure the datepicker input matches our custom input styling */
.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Add a new class for floating label with datepicker */
.indiv-signup-input-label-float {
  top: 8px !important;
  font-size: 12px !important;
  opacity: 0.7 !important;
}

/* Ensure the datepicker input container doesn't interfere with label */
.react-datepicker__input-container {
  background: transparent;
}

/* Keep existing styles and add/modify these */
.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.indiv-signup-input-wrapper.focused .indiv-signup-input-label,
.react-datepicker__input-container input:not(:placeholder-shown) ~ .indiv-signup-input-label {
  top: 4px !important;
  left: 0;
  font-size: 12px;
  opacity: 0.7;
}

/* Calendar styling */
.react-datepicker {
  background-color: #17332D !important;
  border: 1px solid #0D0D0A !important;
  font-family: var(--font-primary) !important;
  color: #0D0D0A !important;
}

.react-datepicker__header {
  background-color: rgba(255, 255, 255, 0.1) !important;
  border-bottom: 1px solid #0D0D0A !important;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day {
  color: #0D0D0A !important;
}

.react-datepicker__day:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected {
  background-color: #315E54 !important;
}

.react-datepicker__month-dropdown,
.react-datepicker__year-dropdown {
  background-color: #17332D !important;
  border: 1px solid #0D0D0A !important;
}

.react-datepicker__month-option,
.react-datepicker__year-option {
  color: #F8F8F5 !important;
}

.react-datepicker__month-option:hover,
.react-datepicker__year-option:hover {
  background-color: rgba(255, 255, 255, 0.1) !important;
}

.react-datepicker__input-container {
  background: transparent;
}

.react-datepicker__triangle {
  display: none;
}

.indiv-signup-input-wrapper.focused .indiv-signup-input-label,
.indiv-signup-input-wrapper .indiv-signup-input-label-float {
  top: 8px;
  font-size: 12px;
  opacity: 0.7;
}

/* Add this to ensure the date input text aligns with other inputs */
.react-datepicker__input-container input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.40px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* Add these new styles */
.indiv-signup-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

.indiv-signup-input-field {
  width: 100%; /* Default full width */
}

.indiv-signup-input-field.half-width {
  width: 50%;
}

.indiv-signup-input-field.third-width {
  width: calc((100% - 32px) / 3); /* Accounts for the gaps between fields */
}

.indiv-signup-form-group {
  width: 100%;
  margin-bottom: 32px;
}

.indiv-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
}

.indiv-signup-input-row:last-child,
.indiv-signup-input-field:last-child {
  margin-bottom: 0;
}

/* Update form group spacing */
.indiv-signup-form-group {
  width: 100%;
  margin-bottom: 32px;
}

.indiv-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
}

/* Add spacing between ALL input fields */
.indiv-signup-input-field {
  margin-bottom: 16px; /* Space between input fields */
  width: 100%;
}

/* Add spacing between input rows */
.indiv-signup-input-row {
  display: flex;
  gap: 16px;
  width: 100%;
  margin-bottom: 16px;
}

/* Remove margin from last elements to prevent extra spacing */
.indiv-signup-form-group:last-child {
  margin-bottom: 0;
}

.indiv-signup-input-row:last-child {
  margin-bottom: 0;
}

/* Only remove bottom margin from input fields that are inside input rows */
.indiv-signup-input-row .indiv-signup-input-field {
  margin-bottom: 0;
}

/* Ensure the main content area has proper spacing */
.indiv-signup-main-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  padding: 5rem;
  overflow: hidden;
}

/* Question Container */
.indiv-signup-radio-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  border-bottom: 1px solid rgba(248, 248, 245, 0.1);
  width: 100%;
}

.indiv-signup-radio-question:last-child {
  border-bottom: none;
}

/* Question Text */
.indiv-signup-question-text {
  color: #0D0D0A;  /* Changed to white */
  font-family: var(--font-primary);
  font-size: 14px;
  font-weight: var(--font-normal);
  line-height: 1.4;
  flex: 1;
  padding-right: 24px;
}

/* Radio Options Container */
.indiv-signup-radio-options {
  display: flex;
  gap: 24px;
  min-width: 160px;  /* Fixed width for the options column */
  justify-content: flex-end;
}

/* Radio Label */
.indiv-signup-radio-label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  font-family: var(--font-primary);
  font-size: 14px;
  color: #315E54;  /* Changed to white */
  white-space: nowrap;
}

/* Custom Radio Button */
.indiv-signup-radio-label input[type="radio"] {
  width: 18px;
  height: 18px;
  border: 2px solid rgba(248, 248, 245, 0.4);  /* Lighter border */
  background: transparent;
}

/* Radio Button Checked State */
.indiv-signup-radio-label input[type="radio"]:checked {
  border-color: #0D0D0A;
}

/* Custom Radio Button Dot */
.indiv-signup-radio-label input[type="radio"]:checked::after {
  background-color: #0D0D0A;  /* White dot */
}

/* Hover State */
.indiv-signup-radio-label:hover input[type="radio"]:not(:checked) {
  border-color: #0D0D0A;
}

/* Form Group for Radio Questions */
.indiv-signup-form-group {
  padding: 0px 0;  /* Keep spacing but remove background */
  margin-top: 24px;
}

.indiv-signup-form-group-header {
  color: #0D0D0A;
  font-size: 18px;
  font-family: var(--font-medium);
  font-weight: var(--font-medium);
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(248, 248, 245, 0.1);
}

/* Special styling for health insurance questions (case 3) */
.indiv-signup-form-group.health-questions {
  background: rgba(248, 248, 245, 0.05);
  border-radius: 8px;
  padding: 24px;
}

/* Custom scrollbar styling */
.indiv-signup-form-section::-webkit-scrollbar {
  width: 8px;
}

.indiv-signup-form-section::-webkit-scrollbar-track {
  background: rgba(248, 248, 245, 0.05);
}

.indiv-signup-form-section::-webkit-scrollbar-thumb {
  background: rgba(248, 248, 245, 0.2);
  border-radius: 4px;
}

.indiv-signup-form-section::-webkit-scrollbar-thumb:hover {
  background: rgba(248, 248, 245, 0.3);
}

/* Radio button styling */
.indiv-signup-radio-options input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #0D0D0A;
  border-radius: 50%;
  margin-right: 8px;
  position: relative;
  cursor: pointer;
}

.indiv-signup-radio-options input[type="radio"]:checked {
  background-color: transparent;
  border-color: #0D0D0A;
}

.indiv-signup-radio-options input[type="radio"]:checked::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  background-color: #0D0D0A;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.indiv-signup-radio-options input[type="radio"]:hover {
  border-color: #315E54;
}

/* Custom scrollbar for main container */
.indiv-signup-container::-webkit-scrollbar {
  width: 8px;
}

.indiv-signup-container::-webkit-scrollbar-track {
  background: rgba(248, 248, 245, 0.05);
}

.indiv-signup-container::-webkit-scrollbar-thumb {
  background: rgba(248, 248, 245, 0.2);
  border-radius: 4px;
}

.indiv-signup-container::-webkit-scrollbar-thumb:hover {
  background: rgba(248, 248, 245, 0.3);
}

/* New scrollable container */
.indiv-signup-scrollable-content {
  flex: 1;
  display: flex;
  flex-direction: row;
  height: 100vh;
  overflow-y: auto; /* Enable vertical scrolling */
  background: #F8F8F5;
}


/* Main content */
.indiv-signup-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  max-width: 50rem;
  width: 100%;
  height: fit-content;
  margin-right: auto;
  margin-left: 0;
}

/* Preview section */
.indiv-signup-preview-section {
  width: 40rem;
  background: #f4f3ef;
  flex-shrink: 0;
  position: sticky;
  top: 0;
  height: 100vh;
}

.two-thirds-width {
  width: 66.66% !important;
}

.indiv-signup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.indiv-signup-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #17332D;
  padding: 2rem;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.indiv-signup-loading-content {
  text-align: center;
  color: white;
}

.indiv-signup-loading-spinner {
  border: 4px solid rgba(255, 255, 255, 0.1);
  border-left-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 20px;
}

.indiv-signup-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 10vh;
  background-color: #17332D;
  color: white;
  text-align: center;
  padding: 20px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

/* Style the select dropdown */
.indiv-signup-input-wrapper select.indiv-signup-input {
  width: 100%;
  background: transparent;
  color: #0D0D0A;
  font-size: 16px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 16px;
  outline: none;
  height: 38px;
  border: none;
  padding: 16px 0 0 0;
  margin: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
}

/* Style the select options */
.indiv-signup-input-wrapper select.indiv-signup-input option {
  background-color: #17332D;
  color: #F8F8F5;
  padding: 8px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
}

/* Add a custom dropdown arrow */
.indiv-signup-input-wrapper select.indiv-signup-input {
  background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1L5 5L9 1' stroke='%23F8F8F5' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 24px;
}

/* Style the select when focused */
.indiv-signup-input-wrapper select.indiv-signup-input:focus {
  background-color: rgba(248, 248, 245, 0.05);
}

/* Ensure the label floats when an option is selected */
.indiv-signup-input-wrapper select.indiv-signup-input:not([value=""]) ~ .indiv-signup-input-label,
.indiv-signup-input-wrapper select.indiv-signup-input:focus ~ .indiv-signup-input-label {
  top: 4px !important;
  font-size: 12px;
  opacity: 0.7;
}

/* DatePicker Container */
.custom-datepicker-calendar {
  background-color: #F8F8F5 !important;
  border: 1px solid #0D0D0A !important;
  border-radius: 0 !important;
  font-family: var(--font-primary) !important;
  box-shadow: 0 2px 4px rgba(13, 13, 10, 0.1);
}

/* Header styling */
.custom-datepicker-calendar .react-datepicker__header {
  background-color: #F8F8F5 !important;
  border-bottom: 1px solid rgba(13, 13, 10, 0.1) !important;
  padding: 16px 8px !important;
}

/* Month and day names */
.custom-datepicker-calendar .react-datepicker__current-month,
.custom-datepicker-calendar .react-datepicker__day-name {
  color: #0D0D0A !important;
  font-family: var(--font-primary) !important;
  font-weight: var(--font-normal) !important;
}

/* Day styling */
.custom-datepicker-calendar .react-datepicker__day {
  color: #0D0D0A !important;
  font-family: var(--font-primary) !important;
  font-weight: var(--font-normal) !important;
}

/* Selected day */
.custom-datepicker-calendar .react-datepicker__day--selected {
  background-color: #315E54 !important;
  color: #F8F8F5 !important;
}

/* Hover state */
.custom-datepicker-calendar .react-datepicker__day:hover {
  background-color: rgba(49, 94, 84, 0.1) !important;
}

/* Dropdown styling */
.custom-datepicker-calendar .react-datepicker__month-dropdown,
.custom-datepicker-calendar .react-datepicker__year-dropdown {
  background-color: #F8F8F5 !important;
  border: 1px solid rgba(13, 13, 10, 0.1) !important;
}

.custom-datepicker-calendar .react-datepicker__month-option,
.custom-datepicker-calendar .react-datepicker__year-option {
  color: #0D0D0A !important;
  font-family: var(--font-primary) !important;
  font-weight: var(--font-normal) !important;
}

/* Dropdown hover state */
.custom-datepicker-calendar .react-datepicker__month-option:hover,
.custom-datepicker-calendar .react-datepicker__year-option:hover {
  background-color: rgba(49, 94, 84, 0.1) !important;
}

/* Remove default triangle */
.custom-datepicker-calendar .react-datepicker__triangle {
  display: none !important;
}

/* Ensure proper z-index */
.custom-datepicker-popper {
  z-index: 100 !important;
}


.calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  font-size: 16px;
}

.datepicker-popup {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 1000;
}