.sect-two-landing-section {
  width: 100%;
  height: 100%;
  padding: 9rem;
  background: #315E54;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.sect-two-content-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 64px;
}

.sect-two-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 24px;
}

.sect-two-headline {
  width: 528px;
  text-align: center;
}

.sect-two-headline-start {
  color: white;
  font-size: 48px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 52.8px;
}

.sect-two-headline-space {
  color: white;
  font-size: 48px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 52.8px;
}

.sect-two-headline-end {
  color: white;
  font-size: 48px;
  font-family: 'Newsreader Display';
  font-style: italic;
  font-weight: var(--font-normal);
  line-height: 52.8px;
}

.sect-two-subheadline {
  width: 408.32px;
  opacity: 0.72;
  text-align: center;
  color: white;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.4px;
}

.sect-two-cta-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
}

.sect-two-cta-button {
  width: 550px;
  padding: 12px;
  border: 1px solid rgba(255, 255, 255, 0.24);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

.sect-two-cta-text {
  color: white;
  font-size: 11px;
  font-family: 'Monument Grotesk';
  font-weight: var(--font-medium);
  text-transform: uppercase;
  line-height: 15.4px;
  text-align: justify;
}

.sect-two-cta-icon-wrapper {
  padding: 4px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}

.sect-two-cta-icon {
  width: 8px;
  height: 8px;
  background: white;
}