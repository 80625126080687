.landing-hero {
  width: 100%;
  height: 100vh;
  padding: 10px 12rem;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  background-color: #17332D;
  background-image: url('../assets/images/boreal-grain.png');
  background-blend-mode: normal;
  background-size: contain;
  background-repeat: repeat;
  box-sizing: border-box
}

.hero-content {
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 64px;
}

.hero-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 3rem;
}

.site-toggle {
  padding: 2px 2px 2px 2px;
  background: transparent;
  border-radius: 100px;
  border: 1px solid white;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
}

.employer-toggle {
  padding: 3px 10px;
  background: white;
  border-radius: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.toggle-text {
  color: black;
  font-size: 12px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 14.4px;
  word-wrap: break-word;
}

.employee-toggle {
  color: white;
  font-size: 12px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 14.4px;
  word-wrap: break-word;
}

.hero-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 20px;
}

.hero-title {
  width: 351px;
  color: white;
  font-size: 48px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 48px;
  word-wrap: break-word;
}

.hero-subtitle {
  width: 335.38px;
  opacity: 0.8;
  color: white;
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  line-height: 22.4px;
  word-wrap: break-word;
}

.cta-container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 16px;
  flex-direction: row;
}

.cta-button {
  padding: 12px 24px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.cta-button.primary {
  background: white;
}

.cta-button.secondary {
  border: 1px solid rgba(255, 255, 255, 0.24);
}

.button-text {
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: var(--font-normal);
  word-wrap: break-word;
}

.primary .button-text {
  color: black;
}

.secondary .button-text {
  color: white;
}

.button-icon {
  padding: 4px;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 10px;
}

.icon-square {
  width: 8px;
  height: 8px;
}

.primary .icon-square {
  background: black;
}

.secondary .icon-square {
  background: white;
}

.employer-toggle,
.employee-toggle {
  cursor: pointer;
  transition: all 0.3s ease;
}

.employer-toggle.active,
.employee-toggle.active {
  background-color: #17332D;
  /* Add any other active state styles */
}

.toggle-switch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.toggle-labels {
  display: flex;
  gap: 40px;
}

.toggle-labels span {
  font-size: 14px;
  color: #666;
  transition: color 0.3s ease;
}

.toggle-labels span.active {
  color: #000;
}

.toggle-switch {
  position: relative;
  background-color: #f0f0f0;
  border-radius: 25px;
  width: 200px;
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 4px;
}

.toggle-label {
  flex: 1;
  text-align: center;
  z-index: 1;
  transition: color 0.3s ease;
  user-select: none;
}

.toggle-label.active {
  color: #ffffff;
}

.toggle-slider {
  position: absolute;
  top: 4px;
  left: 4px;
  width: calc(50% - 4px);
  height: calc(100% - 8px);
  background-color: #000000;
  border-radius: 20px;
  transition: transform 0.3s ease;
}

.toggle-slider.slide-right {
  transform: translateX(100%);
}