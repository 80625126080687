/* typography.css */
:root {
    /* Font Families */
    --font-primary: 'TWK Lausanne 350';
    --font-medium: 'TWK Lausanne 500';
    
    /* Font Sizes */
    --text-xs: 12px;
    --text-sm: 14px;
    --text-base: 16px;
    --text-lg: 18px;
    --text-xl: 24px;
    --text-2xl: 32px;
    --text-3xl: 40px;
    --text-4xl: 48px;
    
    /* Font Weights */
    --font-normal: 350;
    --font-medium: 500;
    
    /* Line Heights */
    --leading-tight: 1.2;
    --leading-normal: 1.4;
    --leading-relaxed: 1.5;
    
    /* Common Text Transforms */
    --text-offset: translateY(2px);
  }
  
  /* Base Typography Classes */
  .text-base {
    font-family: var(--font-primary);
    font-weight: var(--font-normal);
    line-height: var(--leading-relaxed);
    transform: var(--text-offset);
  }
  
  .text-medium {
    font-family: var(--font-medium);
    font-weight: var(--font-medium);
    line-height: var(--leading-relaxed);
    transform: var(--text-offset);
  }
  
  /* Text Size Classes */
  .text-xs { font-size: var(--text-xs); }
  .text-sm { font-size: var(--text-sm); }
  .text-base { font-size: var(--text-base); }
  .text-lg { font-size: var(--text-lg); }
  .text-xl { font-size: var(--text-xl); }
  .text-2xl { font-size: var(--text-2xl); }
  .text-3xl { font-size: var(--text-3xl); }
  .text-4xl { font-size: var(--text-4xl); }
  
